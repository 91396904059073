import { BookingConfig } from '../../dashboard/settings/settings-constants/types-config/types';
import { InviteReadStatus } from './chat-config';
import { OutBoundInfo } from './outbound-config';
import {
  QueueCall,
  QueueCallsAndLists
} from '../../modules/root/new-queue/modals/queue.config';
import { Participant } from '../../modules/root/new-queue/modules/call/modals/call-config';

export enum ScreenShareType {
  default = 'defaultAgentScreen',
  actual = 'actualAgentScreen'
}

export interface InviteReq {
  queue_position_id?: number;
  expiry?: number;
  send_to?: string;
  room_name: string;
  guest_hostname?: string;
  call_type?: string;
  attr?: string;
  queue_id?: number;
}
export interface SendToList {
  link_type: string;
  [key: string]: string | number;
}

export interface InviteRes {
  data: InviteLinks;
}

export interface InviteLinks {
  agent_link: string;
  guest_link: string;
}

export interface AgentListRes {
  status: number;
  data: Agent[];
}

export interface AgentUpdateRes {
  status: number;
  message: string;
}

export interface Agent {
  avatar: string;
  user_id: number;
  first_name: string;
  last_name: string;
  status: AgentStatus;
  role: string;
  shift_id: number;
  call_info?: CallInfo;
  callTimer?: any;
  shortName?: string;
  tenantid: number;
  tenantname: string;
  queues: number[];
  active_call_count?: number;
  title?: string;
}

export interface StatusUpdateReq {
  status: string;
  user_id?: number;
}

export interface CallInfo {
  call_started: number;
  queue_position_id: number;
  guest_name: string;
}

export interface RoomInfoRes {
  data: RoomData;
}
export interface RoomData {
  booking_config: BookingConfig;
  outbound_accepted_timestamp: Date;
  call_config: CallConfig;
  closed: string;
  email: string;
  expiry: number;
  full_name: string;
  guest_cancel_link: string;
  guest_link: string;
  guest_modify_link: string;
  guest_user_id: string;
  invite_id: number;
  outbound: string;
  phone: string;
  queue_id: number;
  room_name: string;
  queue_position_id: number;
  sequence: string;
  start_dt: string;
  started: number;
  status: InviteReadStatus;
  tenantid: number;
  tenantcode: string;
  uuid: string;
  small_room_chat?: string;
  timestamp?: number;
  user_id?: number;
  user_token?: string;
  fcfs_used?: number;
  fcfs_agent_name?: string;
  fcfs_agent_id?: number;
}

export interface CallConfig {
  rpoc: string;
  [key: string]: string;
  virtual_bg: string;
  rescue?: Rescue | any;
}

export interface Rescue {
  time_limit?: number;
  timezone?: string;
  hours?: RescueHours;
}

export interface RescueHours {
  [key: string]: RescueHoursTime[];
}

export interface RescueHoursTime {
  start: string;
  end: string;
}

export interface VideoSocketData {
  roomName: string;
  participant_sid: string;
  switchedToSmall: boolean;
}

export interface AssignAgentSocket {
  agent_user_id: number;
  queue_position_id: number;
}

export interface AgentStatusUpdateSocket {
  user_id?: number | string;
  tenant_id: number[];
  queue_id?: string;
  notification?: UserNotification;
  queue_position_id?: number;
  action: string;
}

export interface UserNotification {
  offline_agent: number;
  online_agent: number;
  in_queue_agent: number;
  in_call_agent: number;
  on_break_agent: number;
}

export interface MemberUpdatedSocket {
  updated: boolean;
  channelSid: string;
  members?: Participant[];
}
export interface RoomEndSocket {
  room_name: string;
  auto_reject?: number;
  domainId: string;
}

export interface AgentRemoved {
  agentRemoved: string;
  roomName: string;
  agentName: string;
  joinedAgentIdentity: string;
  currentAgentIdentity: string;
}

export interface AgentJoinConfig {
  currentParticipant: Participant;
  anotherAgent?: Participant;
  status: number;
  action: string;
}

export enum AgentStatus {
  online = 'Online',
  offline = 'Offline',
  inQueue = 'In Queue',
  inCall = 'In Call',
  onBreak = 'On Break'
}

export interface FeedbackReq {
  tenantid: number;
  event_type: string;
  for_userid: number;
  event_id: string;
  feedback: string;
}

export interface Feedback {
  name: string;
  rating?: Rating;
  comment: string;
  outcome?: string;
  call_category: string;
  blacklist?: number;
}

export interface Rating {
  type: string;
  value: string;
  max_value: string;
}

export interface RoomStatus {
  micOff?: boolean;
  cameraOff?: boolean;
  audioInDevice?: string;
  audioOutDevice?: string;
  videoInDevice?: string;
  blurBackground?: boolean;
  roomCode?: string;
  isObserve?: boolean;
  domainId?: string;
  isCobrowse?: boolean;
  callTime?: number;
  clientIdentity?: string;
  inboundClientIdentity?: string;
  virtualBackgroundImg?: string;
  chatOpened?: boolean;
  inMainRoom?: boolean;
  outboundInfo?: OutBoundInfo;
  isOutboundRoute?: boolean;
}

export enum RoomKeys {
  micOff = 'micOff',
  cameraOff = 'cameraOff',
  audioInDevice = 'audioInDevice',
  audioOutDevice = 'audioOutDevice',
  videoInDevice = 'videoInDevice',
  roomCode = 'roomCode',
  isObserve = 'isObserve',
  domainId = 'domainId',
  chatOpened = 'chatOpened',
  isOutboundRoute = 'isOutboundRoute',
  outboundDomainId = 'outboundDomainId',
  memberSid = 'memberSid',
  unConsumedIndex = 'unConsumedIndex',
  isCobrowse = 'isCobrowse',
  callTime = 'callTime',
  isOutboundInitiated = 'isOutboundInitiated',
  clientIdentity = 'clientIdentity',
  smallRoomMemberSid = 'smallRoomMemberSid',
  inviteCodeSmallRoomChat = 'inviteCodeSmallRoomChat',
  inboundClientIdentity = 'inboundClientIdentity',
  virtualBackgroundImg = 'virtualBackgroundImg',
  lastParticipantInFull = 'lastParticipantInFull',
  inMainRoom = 'inMainRoom',
  participantList = 'participantList',
  outboundInfo = 'outboundInfo'
}

export enum DisabledValue {
  disabledFalse = 'false'
}

export interface SaveQueueListAttr {
  guest_hostname: string;
}

export interface SaveQueueListRes {
  status: number;
  data: QueueCallsAndLists;
}

export interface AgentDataModal {
  agentList: Agent[];
  selectedCall: QueueCall;
  callList: QueueCall[];
}

export interface CreateRoomReq {
  roomName: string;
  record_participants_on_connect: boolean;
}

export interface CallHistoryReq {
  start_date: string;
  end_date: string;
  status: string;
}

export interface CallHistoryRes {
  status: number;
  data: CallHistory[];
}

export enum CallOutcome {
  noCostumer = 'No Customer',
  rejected = 'Rejected',
  notStarted = 'Not Yet Started',
  missed = 'Missed',
  completed = 'Completed'
}

export enum CallType {
  live = 'Live',
  adHoc = 'Ad Hoc',
  scheduled = 'Scheduled',
  outbound = 'Outbound'
}
export interface CallHistory {
  agent_feedback?: null | AgentFeedback;
  audio_used: boolean | number;
  call_outcome?: CallOutcome | string; // valid?
  call_type?: CallType | string; // valid?
  chat_used: boolean | number;
  closed: string;
  composition_progress: number;
  composition_sid: string;
  composition_status: string;
  customer_feedback?: null | AgentFeedback;
  duration: number;
  first_name: string;
  guest_user_id: number;
  last_name: string;
  noChat?: boolean;
  participants: CallParticipant[];
  queue_id: number;
  queue_name: string;
  queue_position_id: number;
  record_participants_on_connect: boolean | number;
  room_name: string;
  room_sid: string;
  showRecording?: boolean;
  started: string;
  startedDate?: string;
  status: string;
  video_used: boolean | number;
}

export interface AgentFeedback {
  average_rating: string | number;
  feedback: FeedbackList[];
  outcome?: null | string;
}

export interface FeedbackList {
  comment: string;
  from_name: string;
  rating_max_value: string | number;
  rating_type: string;
  rating_value: string | number;
}

export interface CallParticipant {
  identity: string;
  user_type: UserType | string;
  user_id: string;
  start_time: string;
  end_time: string;
  duration: number;
  first_name: string;
  last_name: string;
  avatar?: string;
  join_type?: string;
}

export enum UserType {
  guest = 'guest',
  agent = 'agent'
}

export interface ReqRecordingRes {
  recordingSid: string;
  recordingStatus: string;
}

export interface getRecordingRes {
  recordUrl: string;
}

export enum DatesOptions {
  start = 'startDate',
  end = 'endDate'
}

export interface ReportsList {
  queue_position_id: number;
  status: string;
  queue_id: number;
  queue_name: string;
  guest_name: string;
  email: string;
  phone: string;
  postal_code: string;
  language: string;
  comment: string;
  call_date: string;
  booking_datetime: string;
  booking_created: string;
  call_started: null | string;
  call_ended: null | string;
  duration: null | number;
  agent_name: null | string;
}
export interface ReportsRes {
  success: number;
  data: {
    main_dataset: ReportsList[];
  };
}
export const DataGrid: string[] = [
  'queue_position_id',
  'call_outcome',
  'call_type',
  'queue_id',
  'queue_name',
  'guest_name',
  'email',
  'phone',
  'postal_code',
  'language',
  'comment',
  'call_date',
  'booking_datetime',
  'booking_created',
  'call_started',
  'call_ended',
  'duration',
  'agent_name'
];

export const SessionOutCome: string[] = [
  'No Show',
  'Directed to Store',
  'Sale Made'
];

export enum OutComeValues {
  no_show = 'No Show'
}

export enum AssignMethods {
  assign = 'assign',
  un_assign = 'un-assign',
  switch = 'switch'
}

export interface GuestStatusUpdate {
  tenant_id: number[];
  domain_userid: string;
  guest_status: string;
  queue_position_id: number;
  call_status: string;
  agent_id: string;
}

export interface QueueUpdateRes {
  tenant_id: number[];
}

export enum NotificationActions {
  view = 'view',
  start = 'start',
  observe = 'observe'
}

export interface SmallRoomChannelDataRes {
  channel: SmallRoomChannelData;
}
export interface SmallRoomChannelData {
  unique_name: string;
  members_count: number;
  date_updated: string;
  friendly_name: string;
  created_by: string;
  account_sid: string;
  date_created: string;
  sid: string;
  attributes: null;
  service_sid: string;
  type: string;
  messages_count: number;
}

export interface PermissionError {
  camError: boolean;
  micError: boolean;
}

export interface DeviceStatus {
  cameraUpdated?: boolean;
  micUpdated?: boolean;
  switchCamUpdated?: boolean;
  chatUpdated?: boolean;
}

export interface FcfsData {
  fcfs_used: number;
  fcfs_agent_name: string;
}

export interface CallData {
  roomInfo: RoomStatus;
  guestInfo: QueueCall;
  blurBackground: boolean;
  virtualBackground: boolean;
}

export interface RoomEndSubject {
  callEnded: boolean;
  roomName: string;
}
